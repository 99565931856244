<template>
  <nav>
    <v-app-bar
      app
      absolute
      short
      color="transparent"
      elevation="0">
      <div class="nav-logout">
        <a
          v-if="isHomePage"
          data-testid="nav-logout-link"
          class="nav-logout__link"
          :href="logoutLink">SAIR</a>
      </div>
      <router-link data-testid="nav-brand-logo-link" :to="hasHomeLink ? '/' : ''">
        <v-img
          data-testid="nav-brand-logo"
          class="nav-logo"
          src="@/assets/logo_white.png"
          alt="Telessaúde Logo"
          contain />
      </router-link>
      <v-spacer />
    </v-app-bar>
  </nav>
</template>

<script>
import { getTokenMod } from '@/utils/auth.js';

export default {
  name: 'app-navigation',
  data: () => ({
    hasHomeLink: true,
  }),
  computed: {
    isHomePage() {
      return this.$route.name === 'home';
    },
    logoutLink() {
      return `${process.env.VUE_APP_URL_PLATA}/accounts/logout/`;
    },
  },
  mounted() {
    this.hasHomeLink = getTokenMod() === 'PCT';
  },
};
</script>

<style lang="scss" scoped>
// Estilo usado apenas na página de consulta de dados de acesso
#app.page-consulta-acesso .v-app-bar {
  // Cor fixa, para que não seja modificada caso as variáveis de cor sejam modificadas
  background-color: #008C88 !important;
}

.nav-logo {
  max-height: 28px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .nav-logo {
    max-height: 36px;
  }
}

.nav-logout {
  flex: 1;
}

.nav-logout__link {
  color: #FFF;
  text-decoration: none;
}

@media screen and (min-width: 960px) {
  .nav-logout__link {
    padding: 0 39px;
  }
}
</style>
