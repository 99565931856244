import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueMask from 'v-mask';

import App from './App.vue';
import router from './router/index.js';
import store from './store/index.js';
import vuetify from './plugins/vuetify.js';
import './filters/global-filters.js';

Vue.use(VueMask);

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENVIRONMENT === 'PRD') {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_ANALYTICS },
  }, router);
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
