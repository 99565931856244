<template>
  <v-app ref="app-container">
    <app-navigation />
    <v-main>
      <v-slide-x-transition mode="out-in">
        <router-view
          @add-root-class="addRootClass"
          @remove-root-class="removeRootClass" />
      </v-slide-x-transition>
    </v-main>
  </v-app>
</template>

<script>
import { getCookies } from '@/utils/auth.js';
import AppNavigation from './components/AppNavigation/AppNavigation.vue';

export default {
  name: 'App',
  components: {
    'app-navigation': AppNavigation,
  },
  beforeCreate() {
    this.$store.commit('SET_TOKEN', getCookies().servicetoken);
  },
  methods: {
    addRootClass(param) {
      this.$refs['app-container'].$el.classList.add(param);
    },
    removeRootClass(param) {
      this.$refs['app-container'].$el.classList.remove(param);
    },
  },
};
</script>

<style lang="scss">
// Estilo usado apenas na página de consulta de dados de acesso
#app.page-consulta-acesso {
  // Cor fixa, para que não seja modificada caso as variáveis de cor sejam modificadas
  background: #E6E6E6;
}

.print-avoid-break-inside {
  break-inside: avoid;
}
</style>
