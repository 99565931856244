export default {
  // LIGHT VERSION OF DEFAULT THEME
  light: {
    primary: '#19B2AC',
    success: '#00AF50',
    warning: '#FFD751',

    // CUSTOM VARIABLES
    'primary-light': '#75BFBC',
    'primary-dark': '#008C88',

    // THESE VARIABLES ARE BEING USED WITH THEIR DEFAULT VALUES FOR NOW BECAUSE I COULD NOT
    // DETERMINE WHICH COLORS SHOULD BE USED INSTEAD, IN ACCORDANCE TO OUR COMPANY STYLE GUIDE
    // secondary: '#424242',
    // accent: '#82B1FF',
    // info: '#2196F3',
    // error: '#FF5252',
  },
  // DARK VERSION OF THE THEME SHOULD BE PLACED HERE, WE DON'T HAVE IT FOR NOW
  // dark: {},
};
