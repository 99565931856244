<template>
  <div>
    <template v-if="!processList.length">
      <div class="text-center">
        <p class="white--text" data-testid="process-list-empty-msg">
          Você ainda não possui nenhum exame para ser exibido.
        </p>
      </div>
    </template>
    <template v-else>
      <ul data-testid="exams-list" class="exams-list">
        <exams-list-item
          v-for="(process, index) in processList"
          :key="index"
          :process-data="process"/>
      </ul>
    </template>
  </div>
</template>

<script>
import ExamsListItem from '@/components/ExamsList/ExamsListItem.vue';

export default {
  name: 'exams-list',
  components: {
    'exams-list-item': ExamsListItem,
  },
  props: {
    processList: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.exams-list {
  list-style: none;
  padding: 0;
}
</style>
