<template>
  <h1
    data-testid="greetings-message"
    class="greetings-message white--text text-body-1 text-center text-md-h5">
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: 'greetings-message',
};
</script>

<style lang="scss" scoped>
.greetings-message {
  line-height: 1.25;
  max-width: 66%;
  margin: 0 auto 2.5rem;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .greetings-message {
    margin-bottom: 4rem;
  }
}
</style>
