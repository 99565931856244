import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework.js';
import defaultTheme from '@/plugins/themes/default-theme.js';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
    themes: defaultTheme,
  },
});
