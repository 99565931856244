import axios from 'axios';
import appStore from '@/store/index.js';

const getUserToken = () => appStore.getters.getToken;

const plataformaLegacy = axios.create({
  baseURL: process.env.VUE_APP_URL_PLATA,
  withCredentials: false,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

plataformaLegacy.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${getUserToken()}`,
  },
}));

export function getProcessList(page = null) {
  let pageParam = '';
  if (page) pageParam = `?page=${page}`;
  return plataformaLegacy.get(`/api/processo-tdx/${pageParam}`);
}

export function getProcessExamDetail(idProcess) {
  return plataformaLegacy.get(`/api/processo-tdx/${idProcess}`);
}

export function getOphthalmologyPrescription(idProcess) {
  return plataformaLegacy.get(`/api/processo-tdx/prescricao-oculos/${idProcess}`);
}

export function getValidatorPdfProcessExamDetail() {
  return plataformaLegacy.get('/api/validate-processo-tdx/');
}

export function getValidatorPdfOphthalmologyPrescription() {
  return plataformaLegacy.get('/api/validate-processo-tdx/prescricao-oculos/');
}
