import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  isAuthenticated,
  getTokenMod,
  getTokenRoles,
  hasAuthorization as authHasAuthorization,
} from '@/utils/auth.js';
import HomeView from '@/views/HomeView.vue';
import DOCUMENT_TYPES from '@/utils/Enums/DocumentTypes.js';

const documentTypesString = Object.values(DOCUMENT_TYPES).join('|');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      permissions: {
        mods: ['PCT'],
      },
    },
    component: HomeView,
  },
  {
    path: `/:documentType(${documentTypesString})/:idProcess`,
    name: 'document-detail',
    meta: {
      permissions: {
        mods: ['PCT'],
      },
    },
    component: () => import(/* webpackChunkName: "document-detail-view" */ '@/views/DocumentDetailView.vue'),
  },
  {
    path: `/valida-pdf/:documentType(${documentTypesString})`,
    name: 'validate-pdf',
    component: () => import(/* webpackChunkName: "validator-pdf-view" */ '@/views/DocumentDetailView.vue'),
  },
  {
    path: '/consulta-acesso/:uuid',
    name: 'consulta-acesso',
    meta: {
      permissions: {
        mods: ['LEGACY'],
        modsRoles: {
          LEGACY: ['solicitante', 'monitor', 'tecnico', 'administrador'],
        },
      },
    },
    component: () => import(/* webpackChunkName: "consulta-acesso-view" */ '@/views/ConsultaAcessoView.vue'),
  },
  {
    path: '*',
    name: 'page-not-found',
    component: () => import(/* webpackChunkName: "page-not-found-view" */ '@/views/PageNotFoundView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

function hasAuthorization(currentRoute) {
  const modsFilter = currentRoute.meta.permissions?.mods || [];
  const rolesFilter = currentRoute.meta.permissions?.modsRoles;
  const userMod = getTokenMod();
  // Currently, we are checking only the roles for "telediagnostico"
  const userRolesList = getTokenRoles().telediagnostico || [];
  return authHasAuthorization(modsFilter, rolesFilter, userMod, userRolesList);
}

function redirectToAuth() {
  const { VUE_APP_AUTH_WEB } = process.env;
  window.location.replace(`${VUE_APP_AUTH_WEB}/login`);
}

router.beforeEach((to, from, next) => {
  if (!isAuthenticated()) return redirectToAuth();
  if (!hasAuthorization(to)) {
    return next({
      name: 'page-not-found',
      // These options keep the path in the URL
      // see: https://github.com/vuejs/vue-router/issues/977#issuecomment-400982509
      params: [to.path],
      replace: true,
    });
  }

  return next();
});

export default router;
