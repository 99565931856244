<template>
  <div class="exam-icon" v-if="actionType === EXAM_ACTION_TYPES.VER_EXAME">
    <svg data-testid="exam-icon" version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 30.91 40.45" xml:space="preserve"><path id="Caminho_4642" class="st0" d="M27.16 3.73h-6.88A4.954 4.954 0 0 0 14.27.12a4.94 4.94 0 0 0-3.61 3.61H3.78C1.71 3.73.03 5.41.03 7.48v29.19c0 2.07 1.68 3.75 3.75 3.75h23.38c2.07 0 3.75-1.68 3.75-3.75V7.48c0-2.07-1.68-3.75-3.75-3.75zM15.47 2.37c.94 0 1.81.52 2.26 1.36h-4.51a2.54 2.54 0 0 1 2.25-1.36zm-4.59 3.76h11.33v1.38H8.74V6.13h2.14zm17.63 30.54c0 .75-.61 1.35-1.35 1.35H3.78c-.75 0-1.35-.61-1.35-1.35V7.48c0-.75.61-1.35 1.35-1.35h2.55v1.66c0 1.17.95 2.12 2.12 2.12h14.03c1.17 0 2.12-.95 2.12-2.12V6.13h2.55c.75 0 1.35.61 1.35 1.35l.01 29.19z"/><path id="Caminho_4643" class="st0" d="M20.87 19.29h-1.66v-1.66c0-2.08-1.69-3.77-3.77-3.77s-3.77 1.69-3.77 3.77v1.66h-1.66c-2.08 0-3.77 1.7-3.77 3.78s1.69 3.76 3.77 3.77h1.66v1.65c0 2.08 1.69 3.77 3.77 3.77s3.77-1.69 3.77-3.77v-1.66h1.66c2.08 0 3.77-1.7 3.77-3.78a3.77 3.77 0 0 0-3.77-3.76zm1.37 3.77c0 .76-.61 1.37-1.37 1.37h-3.03c-.57 0-1.03.46-1.03 1.03v3.03c-.02.76-.65 1.35-1.41 1.33a1.37 1.37 0 0 1-1.33-1.33v-3.03c0-.57-.46-1.03-1.03-1.03h-3.03c-.76 0-1.37-.61-1.37-1.37 0-.76.61-1.37 1.37-1.37h3.03c.57 0 1.03-.46 1.03-1.03v-3.03c.02-.76.65-1.35 1.41-1.33.73.02 1.31.61 1.33 1.33v3.03c0 .57.46 1.03 1.03 1.03h3.03c.76 0 1.37.61 1.37 1.37z"/></svg>
  </div>
  <div v-else>
    <div class="ophthalmology-icon">
      <svg data-testid="ophthalmology-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.7 17.58" xml:space="preserve"><path d="M43.2 3.79h-2.67A9.399 9.399 0 0 0 33.37 0a9.399 9.399 0 0 0-7.16 3.79h-7.14A9.396 9.396 0 0 0 11.92 0a9.399 9.399 0 0 0-7.16 3.79H1.49c-.94.12-1.59.97-1.48 1.9.1.77.7 1.38 1.47 1.47h1.97c-.97 4.68 2.03 9.26 6.71 10.23 4.68.97 9.26-2.03 10.23-6.71a8.303 8.303 0 0 0-.02-3.52h4.53c-.97 4.68 2.04 9.26 6.72 10.23 4.68.97 9.26-2.04 10.23-6.72a8.209 8.209 0 0 0-.02-3.51h1.37a1.7 1.7 0 0 0 1.47-1.9c-.08-.77-.69-1.37-1.47-1.47zM11.92 14.51c-3.11 0-5.64-2.52-5.64-5.64s2.52-5.64 5.64-5.64a5.64 5.64 0 0 1 5.64 5.64c-.01 3.12-2.53 5.64-5.64 5.64zm21.45 0a5.64 5.64 0 1 1 5.64-5.64c0 3.12-2.52 5.64-5.64 5.64z" class="st0" /></svg>
    </div>
  </div>
</template>

<script>
import SERVICE_TYPES from '@/utils/Enums/ServiceTypes.js';
import EXAM_ACTION_TYPES from '@/utils/Enums/ExamActionTypes.js';

export default {
  name: 'exams-list-action-btn-icon',
  data: () => ({
    SERVICE_TYPES,
    EXAM_ACTION_TYPES,
  }),
  props: {
    // Currently, this prop should always be the same. It may change when we
    // add support to show more types of exams
    serviceType: {
      type: String,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.exam-icon {
  width: 30px;
}

.ophthalmology-icon {
  width: 42px;
  svg {
    display: block;
  }
}

.st0{
  fill:#fff;
}
</style>
