<template>
  <button class="exams-list-item__actions-btn" @click="action">
    <exams-list-action-btn-icon :service-type="serviceType" :action-type="actionType" />
    <span class="exams-list-item__actions-btn-label">{{ getActionLabel() }}</span>
  </button>
</template>

<script>
import EXAM_ACTION_TYPES from '@/utils/Enums/ExamActionTypes.js';
import DOCUMENT_TYPES from '@/utils/Enums/DocumentTypes.js';
import ExamsListActionButtonIcon from './ExamsListActionButtonIcon.vue';

export default {
  name: 'exams-list-action-btn',
  components: {
    'exams-list-action-btn-icon': ExamsListActionButtonIcon,
  },
  props: {
    idProcess: {
      type: String,
      required: true,
    },
    serviceType: {
      type: String,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
  },
  methods: {
    getActionLabel() {
      switch (this.actionType) {
        case EXAM_ACTION_TYPES.VER_EXAME: return 'Ver laudo';
        case EXAM_ACTION_TYPES.VER_PRESCRICAO: return 'Ver receita';
        default: return '';
      }
    },
    action() {
      const actionData = {
        name: 'document-detail',
        params: { idProcess: this.idProcess },
        // params: { idProcess: '122384' }, // DEV: REMOVER DEPOIS
      };

      if (EXAM_ACTION_TYPES.VER_EXAME === this.actionType) {
        actionData.params.documentType = DOCUMENT_TYPES.EXAME;
        this.$router.push(actionData);
      }

      if (EXAM_ACTION_TYPES.VER_PRESCRICAO === this.actionType) {
        actionData.params.documentType = DOCUMENT_TYPES.PRESCRICAO;
        // actionData.params.idProcess = '122384'; // DEV: REMOVER DEPOIS
        this.$router.push(actionData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.exams-list-item__actions-btn {
  &:nth-child(1) {
    margin-bottom: .5rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: .625rem;
  font-size: .875rem;
  font-weight: bold;
  vertical-align: bottom;
  width: 100%;
  max-width: 110px;
  height: 88px;
  border-radius: 4px;
  color: #fff;
  background-color: var(--v-primary-base);
  box-shadow: 0 1px 3px 0px rgba(0,0,0,.29);
  transition: all 200ms ease;
  &:hover {
    background-color: var(--v-primary-dark-base);
    box-shadow: 0 1px 3px 1px rgba(0,0,0,.29);
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .exams-list-item__actions-btn {
    &:nth-child(1) {
      margin-bottom: 1rem;
    }
    flex-direction: row;
    max-width: none;
    max-width: 228px;
    height: 66px;
    justify-content: center;
    border-radius: 12px;
    font-size: 1.125rem;
    & > :first-child {
      margin-right: 1rem;
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .exams-list-item__actions-btn {
    font-size: 1.25rem;
  }
}
</style>
