<template>
  <v-container class="mt-4 mt-md-8">
    <v-row>
      <v-col>
        <greetings-message>
          Olá <span class="text-capitalize">{{ userFirstName }}</span>,
          confira aqui o andamento dos seus exames.
        </greetings-message>
      </v-col>
    </v-row>

    <exams-list v-if="processList" :process-list="processList" />
    <template v-if="processListNextPage">
      <v-btn
        data-testid="process-list-load-more-btn"
        @click="fetchProcessList"
        :loading="isLoadingProcessList"
        block
        large
        color="primary">Carregar Mais Exames</v-btn>
    </template>

    <template v-if="isLoadingProcessList && !processListNextPage">
      <div class="text-center">
        <v-progress-circular data-testid="process-list-loading-icon" color="white" indeterminate />
      </div>
    </template>

    <template v-if="errorLoadingProcessList">
      <div class="text-center">
        <p class="error pa-4 rounded" data-testid="process-list-error-message">
          <v-icon color="white" class="mr-sm-2">mdi-alert-circle</v-icon>
          <span class="d-block d-sm-inline white--text">
            Ocorreu um erro ao carregar sua lista de exames, tente novamente mais tarde.
          </span>
        </p>
      </div>
    </template>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import GreetingsMessage from '@/components/GreetingsMessage/GreetingsMessage.vue';
import ExamsList from '@/components/ExamsList/ExamsList.vue';
import { getProcessList } from '@/services/plataforma-legacy.js';

export default {
  name: 'home-view',
  components: {
    'greetings-message': GreetingsMessage,
    'exams-list': ExamsList,
  },
  data: () => ({
    isLoadingProcessList: true,
    errorLoadingProcessList: false,
    processList: null,
    processListNextPage: null,
  }),
  computed: {
    ...mapState(['token']),
    ...mapGetters(['userFullName']),
    userFirstName() {
      return this.userFullName.split(' ')[0].toLowerCase();
    },
  },
  async mounted() {
    await this.fetchProcessList();
  },
  methods: {
    async fetchProcessList() {
      try {
        this.isLoadingProcessList = true;

        const response = await getProcessList(this.processListNextPage);
        this.processListNextPage = null;

        if (response.data.next !== null) {
          const pageParameter = /page=[0-9]+/.exec(response.data.next);
          const nextPage = pageParameter ? pageParameter[0].split('=')[1] : null;
          this.processListNextPage = nextPage;
        }

        this.processList = [
          ...(this.processList || []),
          ...response.data.results,
        ];
      } catch (error) {
        this.errorLoadingProcessList = true;
        console.error(error.response || error);
      } finally {
        this.isLoadingProcessList = false;
      }
    },
  },
};
</script>
