import Vue from 'vue';
import Vuex from 'vuex';
import { decodeJwt } from '@/utils/auth.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
  },
  getters: {
    getToken: (state) => state.token,
    userFullName: (state) => {
      const tokenData = decodeJwt(state.token);

      if (tokenData.mod === 'PCT') {
        return tokenData.claims.name;
      }

      return tokenData.claims.username;
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
  },
  actions: {},
  modules: {},
});
