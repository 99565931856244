export function getCookies() {
  if (!document.cookie) return {};

  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
  return cookies.reduce((all, cookie) => {
    const [name, value] = cookie.split('=');
    return {
      [name]: value,
      ...all,
    };
  }, {});
}

export function decodeJwt(token) {
  const tokenData = token.split('.')[1];
  return JSON.parse(window.atob(tokenData));
}

export function isAuthenticated() {
  const cookiesHashMap = getCookies();
  const jwt = cookiesHashMap?.servicetoken || cookiesHashMap?.temporarytoken;

  if (!jwt) {
    return false;
  }

  const sessionToken = decodeJwt(jwt);
  const jwtExpiration = sessionToken.exp * 1000;
  const dateToday = new Date();

  return jwtExpiration > dateToday.getTime();
}

export function getTokenMod() {
  const cookiesHashMap = getCookies();
  if (!cookiesHashMap?.servicetoken) return {};
  return decodeJwt(cookiesHashMap.servicetoken).mod;
}

export function getTokenRoles() {
  const cookiesHashMap = getCookies();
  if (!cookiesHashMap?.servicetoken) return {};
  return decodeJwt(cookiesHashMap.servicetoken).claims.roles || {};
}

export function hasAuthorization(modsFilter, rolesFilter, userMod, userRolesList) {
  if (!modsFilter.length) return true; // There are no access restrictions
  if (!modsFilter.includes(userMod)) return false; // User does not have the required mod permission

  // There are no roles filter or no role filter for user's mod,
  // anyone with that mod has the required permission
  if (!rolesFilter || !rolesFilter[userMod]) return true;

  return !userRolesList.length
    ? false // Token has no roles
    // Verifies if user has any of the roles requested for its mod
    : rolesFilter[userMod].some((role) => userRolesList.includes(role));
}
