import Vue from 'vue';

Vue.filter('olhoAbbr', (olhoValue) => {
  const accepted = ['direito', 'esquerdo'];

  if (!accepted.includes(olhoValue)) {
    throw new Error(`Valor inválido para criar abreviação de olho: "${olhoValue}"`);
  }

  return `O${olhoValue[0].toUpperCase()}`;
});
